import { getStore } from './getStoreId';

const pushToMicroSite = (home) => {
  const { pathname } =
    typeof window !== 'undefined' ? window.location : { pathname: '' };
  const path = getStore(pathname);
  if (path !== process.env.NEXT_PUBLIC_STORE_ID) return `/${path}`;
  return home ? '/' : '';
};
export default pushToMicroSite;
